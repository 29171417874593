import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import MetricCard from "../common/MetricCard";
import { formatCurrency } from "../utils/numberUtils";

const cardTitleMappings = {
  scrubbedAmount: "Scrubbed Amount",
  highestPerformingScrub: "Highest Performing Scrub",
  totalInvoiceAmount: "Total Invoice Amount",
};

const DEFAULT_SUBTEXT = "vs last quarter";

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const MetricCards = ({ currentQuarter }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch("/api/dashboard/metric_cards");

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving metrics data",
      });
    }
  }, [error]);

  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => {
        const key = Object.keys(data || {})[index];
        const cardData = data?.[key];

        return (
          <MetricCard
            key={key || index}
            title={cardData ? `${cardTitleMappings[key]} (${currentQuarter})` : ""}
            value={cardData ? formatCurrency(cardData.amount, true) : undefined}
            subtext={cardData?.subtext || DEFAULT_SUBTEXT}
            isLoading={isLoading}
            percentChange={cardData?.percentChange}
          />
        );
      })}
      <Notice details={notice} />
    </>
  );
};

MetricCards.propTypes = {
  currentQuarter: PropTypes.string.isRequired,
};

export default MetricCards;
