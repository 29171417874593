import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import useFetch from "../../hooks/useFetch";
import HorizontalBar from "../../common/charts/HorizontalBar";
import ToggleSwitch from "../../common/ToggleSwitch";
import Notice from "../../common/Notice";
import TableLoadingSkeleton from "../../common/TableLoadingSkeleton";
import LoadingSkeletonWrapper from "../../common/LoadingSkeletonWrapper";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";
import { addCommasToNumber, formatCurrency, formatToPercent } from "../../utils/numberUtils";

const { secondary, highlightBlue } = colors;

const TOGGLE_SWITCH_OPTIONS = { amount: "amount", lines: "lines" };

const DistributionOfProducts = ({ invoiceId }) => {
  const [selectedToggleOption, setSelectedToggleOption] = useState(TOGGLE_SWITCH_OPTIONS.amount);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving Distribution of Products and Scrub Rates data",
  });

  const { data, isLoading, error } = useFetch(
    `/api/invoices/${invoiceId}/scrub_details_by_product`,
  );

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  const isAmountToggleSelected = selectedToggleOption === TOGGLE_SWITCH_OPTIONS.amount;
  const { formattedInvoiceTotal, formattedPercentOfInvoiceTotal } = useMemo(() => {
    const totals = data?.reduce(
      (acc, curr) => ({
        invoiceTotal: acc.invoiceTotal + curr[selectedToggleOption].total,
        percentOfInvoiceTotal:
          acc.percentOfInvoiceTotal + curr[selectedToggleOption].percentOfInvoice,
      }),
      { invoiceTotal: 0, percentOfInvoiceTotal: 0 },
    );

    return {
      formattedInvoiceTotal: isAmountToggleSelected
        ? formatCurrency(totals?.invoiceTotal)
        : addCommasToNumber(totals?.invoiceTotal),
      formattedPercentOfInvoiceTotal: formatToPercent(totals?.percentOfInvoiceTotal, 1),
    };
  }, [data, selectedToggleOption]);

  const formattedData =
    data?.map((obj) => ({
      product: obj.product,
      Scrubbed: obj[selectedToggleOption].scrubbed,
      Validated: obj[selectedToggleOption].notScrubbed,
      total: isAmountToggleSelected
        ? formatCurrency(obj[selectedToggleOption].total)
        : addCommasToNumber(obj[selectedToggleOption].total),
      percentOfInvoice: formatToPercent(obj[selectedToggleOption].percentOfInvoice, 1),
      scrubRate: formatToPercent(obj[selectedToggleOption].scrubRate, 1),
    })) || [];

  function renderTooltip({ data: { product, Scrubbed = 0, Validated = 0 } }) {
    const formatValue = (value) => {
      return isAmountToggleSelected
        ? formatCurrency(value)
        : `${addCommasToNumber(value)} ${value === 1 ? "Line" : "Lines"}`;
    };

    return (
      <div className="tooltip--position-bottom">
        <div className="tooltip__content">
          <div className="tooltip__content__title" style={{ marginBottom: 16 }}>
            {product}
          </div>
          <div className="tooltip__content__details">
            <div className="tooltip__content__details__row">
              <div
                className="tooltip__content__details__symbol"
                style={{ backgroundColor: secondary }}
              />
              <span>{formatValue(Scrubbed)} Scrubbed</span>
            </div>
            <div className="tooltip__content__details__row">
              <div
                className="tooltip__content__details__symbol"
                style={{ backgroundColor: highlightBlue }}
              />
              <span>{formatValue(Validated)} Validated</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderTableBodyRows() {
    if (isLoading) return <TableLoadingSkeleton columns={4} rows={6} />;

    return formattedData.map((datum) => (
      <tr key={datum.product}>
        <td>{datum.product}</td>
        <td className="align-right">{datum.total}</td>
        <td className="align-right">{datum.percentOfInvoice}</td>
        <td className="align-right">{datum.scrubRate}</td>
      </tr>
    ));
  }

  return (
    <div className="card" data-testid="distribution-of-products">
      <div className="card__actions">
        <div className="card__title">Distribution of Products and Scrub Rates</div>
        <ToggleSwitch
          options={["$ Amount", "Lines"]}
          onSelection={() => {
            setSelectedToggleOption((prev) =>
              prev === TOGGLE_SWITCH_OPTIONS.amount
                ? TOGGLE_SWITCH_OPTIONS.lines
                : TOGGLE_SWITCH_OPTIONS.amount,
            );
          }}
        />
      </div>
      <HorizontalBar
        data={[...formattedData].reverse()}
        showLegend={true}
        isLoading={isLoading}
        keys={["Scrubbed", "Validated"]}
        indexBy="product"
        colors={[secondary, highlightBlue]}
        height={350}
        renderTooltip={renderTooltip}
        noDataMessage="There is no data that matches your filter selection"
        valueType={isAmountToggleSelected ? "currency" : "unformatted"}
        bottomTickValues={3}
      />
      <table className="table table--basic table--mt-24">
        <thead>
          <tr>
            <th>Product</th>
            <th className="align-right">
              {isAmountToggleSelected ? "$ Amount Invoiced" : "Lines"}
            </th>
            <th className="align-right">% of Invoice</th>
            <th className="align-right">Scrub Rate</th>
          </tr>
        </thead>
        <tbody>{renderTableBodyRows()}</tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <td className="align-right">
              <LoadingSkeletonWrapper isLoading={isLoading} width="100%">
                {formattedInvoiceTotal}
              </LoadingSkeletonWrapper>
            </td>
            <td className="align-right">
              <LoadingSkeletonWrapper isLoading={isLoading} width="100%">
                {formattedPercentOfInvoiceTotal}
              </LoadingSkeletonWrapper>
            </td>
            <td className="align-right">--</td>
          </tr>
        </tfoot>
      </table>
      <Notice details={notice} />
    </div>
  );
};

DistributionOfProducts.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default DistributionOfProducts;
