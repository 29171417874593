import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "../../hooks/useFetch";
import Notice from "../../common/Notice";
import ScrubbedAmountsOfHighestVolumeProducts from "./ScrubbedAmountsOfHighestVolumeProducts";
import ProductPerformance from "./ProductPerformance";

const ProductCards = ({ customerId }) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving product data",
  });

  const { data, isLoading, error } = useFetch(`/api/customer_report/${customerId}/products`);

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  return (
    <>
      <div className="cards-container cards-container--row">
        <ScrubbedAmountsOfHighestVolumeProducts data={data?.chart} isLoading={isLoading} />
        <ProductPerformance data={data?.table} isLoading={isLoading} />
      </div>
      <Notice details={notice} />
    </>
  );
};

ProductCards.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default ProductCards;
