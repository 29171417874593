import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import MetricCard from "../common/MetricCard";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";

const cardTitleMappings = {
  highestPerformingScrub: "Highest Performing Scrub",
  scrubRate: "Scrub Rate",
  scrubbedAmount: "Scrubbed Amount",
  totalInvoiceAmount: "Total Invoice Amount",
};

const DEFAULT_SUBTEXT = "Last 12 Months";

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const MetricCards = ({ customerId }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch(`/api/customer_report/${customerId}/metric_cards`);

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving metrics data",
      });
    }
  }, [error]);

  const cardsToRender = data || (isLoading ? new Array(4).fill({}) : []);

  return (
    <>
      {cardsToRender.map((item, index) => {
        const { type, amount, subtext = DEFAULT_SUBTEXT } = item || {};
        const formattedValue =
          type === "scrubRate" ? formatToPercent(amount) : formatCurrency(amount, true);

        return (
          <MetricCard
            key={type || index}
            title={type ? cardTitleMappings[type] : ""}
            value={amount === null ? undefined : formattedValue}
            subtext={subtext || undefined}
            isLoading={isLoading}
          />
        );
      })}
      <Notice details={notice} />
    </>
  );
};

MetricCards.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default MetricCards;
