import React from "react";
import PropTypes from "prop-types";
import TableLoadingSkeleton from "../../common/TableLoadingSkeleton";
import LoadingSkeletonWrapper from "../../common/LoadingSkeletonWrapper";
import { getPerformanceTotals } from "../customerReportUtils";
import { formatCurrency, formatToPercent } from "../../utils/numberUtils";

const PlanPerformance = ({ data = [], isLoading = false }) => {
  const { scrubbedAmount, scrubRate, totalInvoiceAmount, percentOfInvoice } =
    getPerformanceTotals(data);

  const renderTableBodyRows = () => {
    if (isLoading) return <TableLoadingSkeleton columns={5} rows={6} />;

    return data?.map(
      ({ plan, scrubbedAmount, scrubRate, totalInvoiceAmount, percentOfInvoice }) => (
        <tr key={plan}>
          <td>{plan}</td>
          <td className="align-right">{formatCurrency(scrubbedAmount, true)}</td>
          <td className="align-right">{formatToPercent(scrubRate, 1)}</td>
          <td className="align-right">{formatCurrency(totalInvoiceAmount, true)}</td>
          <td className="align-right">{formatToPercent(percentOfInvoice, 1)}</td>
        </tr>
      ),
    );
  };

  return (
    <div className="card card--width-60pct" style={{ height: 440 }}>
      <div className="card__title">Plan Performance (Last 12 Months)</div>
      <div className="table--scroll">
        <table className="table table--basic table--layout-fixed">
          <thead>
            <tr>
              <th>Product</th>
              <th className="align-right">Scrubbed Amount</th>
              <th className="align-right">Scrub Rate</th>
              <th className="align-right">Total Invoice Amount</th>
              <th className="align-right">% of Invoice</th>
            </tr>
          </thead>
          <tbody>{renderTableBodyRows()}</tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <td className="align-right">
                <LoadingSkeletonWrapper isLoading={isLoading} width="100%">
                  {scrubbedAmount}
                </LoadingSkeletonWrapper>
              </td>
              <td className="align-right">
                <LoadingSkeletonWrapper isLoading={isLoading} width="100%">
                  {scrubRate}
                </LoadingSkeletonWrapper>
              </td>
              <td className="align-right">
                <LoadingSkeletonWrapper isLoading={isLoading} width="100%">
                  {totalInvoiceAmount}
                </LoadingSkeletonWrapper>
              </td>
              <td className="align-right">
                <LoadingSkeletonWrapper isLoading={isLoading} width="100%">
                  {percentOfInvoice}
                </LoadingSkeletonWrapper>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

PlanPerformance.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.string,
      scrubbedAmount: PropTypes.number,
      scrubRate: PropTypes.number,
      totalInvoiceAmount: PropTypes.number,
      percentOfInvoice: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
};

export default PlanPerformance;
