import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import CustomLegend from "./CustomLegend";
import NoResults from "../../common/NoResults";
import { chartTheme, chartTextStyle } from "./themes";
import textWidth from "../../utils/textWidth";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";
import { formatToCompactNumber, formatToPercent } from "../../utils/numberUtils";

const { white, primaryText, primaryBackground } = colors;

const PADDING = 16;

const { fontSize, fontFamily } = chartTextStyle;

const getDataTypeConfig = (valueType, keys, bottomTickValues) => {
  const layers = ["grid", "axes", "bars", "markers", "legends"];
  const axisBottomValues = { tickSize: 0 };
  if (bottomTickValues) axisBottomValues.tickValues = bottomTickValues;

  switch (valueType) {
    case "percent":
      return {
        valueScale: { type: "linear", min: 0, max: 1 },
        axisBottom: {
          ...axisBottomValues,
          tickValues: [0, 0.5, 1],
          format: (val) => (val === 0 ? val : formatToPercent(val)),
        },
        layers: [...layers, (bars) => CustomBarLabel(bars, keys)],
      };
    case "currency":
      return {
        valueScale: { type: "linear" },
        axisBottom: {
          ...axisBottomValues,
          format: (val) => (val === 0 ? val : `$${formatToCompactNumber(val)}`),
        },
        layers,
      };
    case "unformatted":
    default:
      return {
        valueScale: { type: "linear" },
        axisBottom: {
          ...axisBottomValues,
          format: (val) => (val === 0 ? val : formatToCompactNumber(val)),
        },
        layers,
      };
  }
};

const CustomBarLabel = ({ bars }, keys) =>
  bars.map((bar) => {
    const isStacked = keys.length > 1;
    const barValue = formatToPercent(bar.data.value);
    const barWidth = bar.width;
    const barValueWidth = textWidth(barValue, chartTextStyle, fontFamily);
    const barValueWidthIsGreaterThanBarWidth = barValueWidth + PADDING + 8 > barWidth; // Added 8 to account for extra rect element width
    const barValueXoffset = barValueWidthIsGreaterThanBarWidth
      ? barWidth + PADDING / 2
      : barWidth - PADDING / 2;

    if (barValueWidthIsGreaterThanBarWidth && isStacked) return;

    return (
      <g
        key={bar.key}
        transform={`translate(${bar.x + barValueXoffset}, ${bar.y + bar.height / 2})`}
      >
        {!barValueWidthIsGreaterThanBarWidth && (
          <rect
            // `x`, `y`, and `width` adjustment values were visually determined
            x={-(barValueWidth + 9)}
            y={-10}
            width={barValueWidth + 11}
            height={18} // 14px (height of 12px font-size) + 2px (top padding) + 2px (bottom padding)
            fill={white}
            opacity={0.5}
            rx={4}
          />
        )}
        <text
          x={-2}
          y={0}
          textAnchor={barValueWidthIsGreaterThanBarWidth && !isStacked ? "start" : "end"}
          dominantBaseline="middle"
          fill={primaryText}
          style={{ fontSize: chartTextStyle.fontSize, fontWeight: 700 }}
        >
          {barValue}
        </text>
      </g>
    );
  });

const HorizontalBar = ({
  data = [],
  valueType = "unformatted",
  showLegend = false,
  legendLabelMappings = null,
  isLoading,
  indexBy,
  keys,
  colors,
  bottomTickValues = null,
  height = 400,
  renderTooltip = null,
  noDataMessage = "No data available at this time.",
}) => {
  const legendProps = keys.map((key, i) => ({
    label: legendLabelMappings ? legendLabelMappings[key] : key,
    color: colors[i],
  }));

  const leftMargin = Math.max(
    ...data.map((dataPoint) => textWidth(dataPoint[indexBy], fontSize, fontFamily)),
  );

  function handleRenderTooltip(props) {
    if (renderTooltip) return renderTooltip(props);
  }

  function handleMouseEnter(_, event) {
    if (renderTooltip) event.target.style.cursor = "pointer";
  }

  function handleMouseLeave(_, event) {
    if (renderTooltip) event.target.style.cursor = "default";
  }

  if (!isLoading && !data.length)
    return (
      <NoResults
        description={noDataMessage}
        height={height}
        imgWidth={80}
        backgroundColor={primaryBackground}
      />
    );

  if (isLoading) {
    return (
      <Skeleton variant="rounded" height={height} data-testid="horizontal-bar-loading-skeleton" />
    );
  }

  return (
    <>
      {showLegend && <CustomLegend className="custom-legend--mb-0" legendProps={legendProps} />}
      <div className="bar" style={{ height: `${height}px` }}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={indexBy}
          margin={{
            top: 0,
            right: PADDING,
            bottom: 20,
            left: leftMargin + PADDING,
          }}
          padding={0.3}
          layout="horizontal"
          indexScale={{ type: "band", round: true }}
          enableGridY={false}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: PADDING,
          }}
          enableLabel={false}
          tooltip={handleRenderTooltip}
          theme={chartTheme}
          labelSkipWidth={true}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...getDataTypeConfig(valueType, keys, bottomTickValues)}
        />
      </div>
    </>
  );
};

HorizontalBar.propTypes = {
  data: PropTypes.array,
  valueType: PropTypes.oneOf(["percent", "currency", "unformatted"]),
  showLegend: PropTypes.bool,
  legendLabelMappings: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  height: PropTypes.number,
  renderTooltip: PropTypes.func,
  bottomTickValues: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  noDataMessage: PropTypes.string,
};

export default HorizontalBar;
