import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import TableLoadingSkeleton from "../common/TableLoadingSkeleton";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const CustomerPerformance = () => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch("/api/dashboard/customer_performance");

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving customer performance data",
      });
    }
  }, [error]);

  const renderTableBodyRows = () => {
    if (isLoading) return <TableLoadingSkeleton columns={4} rows={6} />;

    return data?.map((datum) => (
      <tr key={datum.customer}>
        <td>{datum.customer}</td>
        <td className="align-right">{formatCurrency(datum.scrubbedAmount, true)}</td>
        <td className="align-right">{formatCurrency(datum.totalInvoiceAmount, true)}</td>
        <td className="align-right">{formatToPercent(datum.scrubRate)}</td>
      </tr>
    ));
  };

  return (
    <div className="card">
      <div className="card__title">Customer Performance (Last 4 Quarters)</div>
      <div className="table--scroll">
        <table className="table table--basic table--layout-fixed">
          <thead>
            <tr>
              <th>Customer</th>
              <th className="align-right">Scrubbed Amount</th>
              <th className="align-right">Total Invoice Amount</th>
              <th className="align-right">Scrub Rate</th>
            </tr>
          </thead>
          <tbody>{renderTableBodyRows()}</tbody>
        </table>
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default CustomerPerformance;
