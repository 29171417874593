import React from "react";
import PropTypes from "prop-types";
import { ArrowOutward } from "../common/icons";
import { formatToPercent } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const { primary } = colors;

const TrendPill = ({ value }) => {
  const isNegative = value < 0;

  return (
    <div className="trend-pill">
      <div className={`trend-pill__icon ${isNegative ? "trend-pill__icon--negative" : ""}`}>
        <ArrowOutward color={primary} size={12} />
      </div>
      {`${isNegative ? "-" : "+"}${formatToPercent(Math.abs(value))}`}
    </div>
  );
};

TrendPill.propTypes = {
  value: PropTypes.number,
};

export default TrendPill;
