import React from "react";
import PropTypes from "prop-types";
import Line from "../../common/charts/Line";
import { formatCurrency } from "../../utils/numberUtils";

const HighestVolumePlansScrubbedAmounts = ({ data, isLoading }) => {
  function renderTooltip({ slice: { points } }) {
    const month = points[0].data.x;

    return (
      <div className="tooltip__content">
        <div className="tooltip__content__title">{month}</div>
        <div className="tooltip__content__details">
          {[...points].reverse().map((point) => {
            const scrubbedAmount = formatCurrency(point.data.y, true);
            const planName = point.serieId;

            return (
              <div key={point.id} className="tooltip__content__details__row">
                <span
                  className="tooltip__content__details__symbol"
                  style={{ backgroundColor: point.serieColor }}
                />
                <span>
                  {point.data?.noData ? "No rebates invoiced" : scrubbedAmount} {planName}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card card--width-40pct">
        <div className="card__title">Scrubbed Amounts of Highest Volume Plans</div>
        <Line
          data={data || []}
          height={286}
          isLoading={isLoading}
          renderTooltip={renderTooltip}
          bottomTickFormatter={(val) => val.slice(0, 3)}
          noDataMessage="No data was retrieved."
          valueType="currency"
        />
      </div>
    </>
  );
};

HighestVolumePlansScrubbedAmounts.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
};

export default HighestVolumePlansScrubbedAmounts;
