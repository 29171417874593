import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import HorizontalBar from "../common/charts/HorizontalBar";
import Notice from "../common/Notice";
import useFetch from "../hooks/useFetch";
import { formatCurrency } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const CHART_COLORS = [colors.secondary, colors.highlightBlue];
const LEGEND_LABEL_MAPPINGS = { scrubbedAmount: "Scrubbed", validatedAmount: "Validated" };

const ProductDistribution = ({ currentQuarter }) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving product distribution data",
  });

  const { data, isLoading, error } = useFetch("/api/dashboard/scrubbed_amounts_by_product");

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  function renderTooltip({ data: { product, scrubbedAmount, validatedAmount } }) {
    return (
      <div className="tooltip--position-bottom">
        <div className="tooltip__content">
          <div className="tooltip__content__title">{product}</div>
          <div className="tooltip__content__details">
            <div className="tooltip__content__details__row">
              <span
                className="tooltip__content__details__symbol"
                style={{ backgroundColor: CHART_COLORS[0] }}
              />
              <span>{formatCurrency(scrubbedAmount, true)} Scrubbed</span>
            </div>
            <div className="tooltip__content__details__row">
              <span
                className="tooltip__content__details__symbol"
                style={{ backgroundColor: CHART_COLORS[1] }}
              />
              <span>{formatCurrency(validatedAmount, true)} Validated</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" data-testid="dashboard-product-distribution">
      <div className="card__title">Product Distribution ({currentQuarter})</div>
      <HorizontalBar
        data={data?.reverse() || []}
        isLoading={isLoading}
        indexBy="product"
        keys={["scrubbedAmount", "validatedAmount"]}
        colors={CHART_COLORS}
        valueType="currency"
        bottomTickValues={2}
        renderTooltip={renderTooltip}
        height={224}
        noDataMessage="No data was retrieved."
        legendLabelMappings={LEGEND_LABEL_MAPPINGS}
        showLegend
      />
      <Notice details={notice} />
    </div>
  );
};

ProductDistribution.propTypes = {
  currentQuarter: PropTypes.string.isRequired,
};
export default ProductDistribution;
