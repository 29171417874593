import { formatCurrency, formatToPercent } from "../utils/numberUtils";

const getPerformanceTotals = (data) => {
  const summedValues =
    data?.reduce(
      (acc, curr) => ({
        scrubbedAmount: acc.scrubbedAmount + curr.scrubbedAmount,
        totalInvoiceAmount: acc.totalInvoiceAmount + curr.totalInvoiceAmount,
        percentOfInvoice: acc.percentOfInvoice + curr.percentOfInvoice,
      }),
      { scrubbedAmount: 0, totalInvoiceAmount: 0, percentOfInvoice: 0 },
    ) || {};
  summedValues.scrubRate = summedValues.scrubbedAmount / summedValues.totalInvoiceAmount || 0;

  return {
    scrubbedAmount: formatCurrency(summedValues.scrubbedAmount, true),
    scrubRate: formatToPercent(summedValues.scrubRate, 1),
    totalInvoiceAmount: formatCurrency(summedValues.totalInvoiceAmount, true),
    percentOfInvoice: formatToPercent(summedValues.percentOfInvoice, 1),
  };
};

export { getPerformanceTotals };
