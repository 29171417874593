import React from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";
import { Skeleton } from "@mui/material";
import CustomLegend from "./CustomLegend";
import NoResults from "../../common/NoResults";
import { formatToCompactNumber, formatToPercent } from "../../utils/numberUtils";
import textWidth from "../../utils/textWidth";
import { chartTheme, chartTextStyle } from "./themes";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const {
  lightBlue,
  lightPurple,
  goldenYellow,
  purpleBlue,
  peachOrange,
  darkTeal,
  primaryBackground,
} = colors;
const { fontSize, fontFamily } = chartTextStyle;

const DEFAULT_COLORS = [lightBlue, lightPurple, goldenYellow, purpleBlue, peachOrange, darkTeal];
const LEGEND_HEIGHT = 36;

const formatMethods = {
  percent: (val) => formatToPercent(val),
  currency: (val) => `$${formatToCompactNumber(val)}`,
  unformatted: (val) => formatToCompactNumber(val),
};

const Line = ({
  data = [],
  valueType = "unformatted",
  isLoading,
  colors = DEFAULT_COLORS,
  bottomTickFormatter = () => {},
  renderTooltip = () => {},
  noDataMessage = "No data available at this time.",
  height = 400,
}) => {
  const legendProps = data.map((datum, i) => ({ label: datum.id, color: colors[i] }));

  if (!isLoading && !data.length)
    return (
      <NoResults
        description={noDataMessage}
        height={height + LEGEND_HEIGHT}
        imgWidth={80}
        backgroundColor={primaryBackground}
      />
    );

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        height={height + LEGEND_HEIGHT}
        data-testid="line-loading-skeleton"
      />
    );
  }

  const leftMargin = Math.max(
    ...data.map((lineData) => {
      const largestYValueForLine = Math.max(...lineData.data.map(({ y }) => y));
      const yValueText = formatMethods[valueType](largestYValueForLine);

      return textWidth(yValueText, fontSize, fontFamily);
    }),
  );

  return (
    <>
      <CustomLegend legendProps={legendProps} />
      <div className="line" style={{ height }}>
        <ResponsiveLine
          data={data}
          colors={colors}
          // Additional 16 in left margin is to account for `axisLeft.tickPadding`
          margin={{ top: 5, right: 10, bottom: 25, left: leftMargin + 16 }}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: 0, max: "auto" }}
          pointSize={0}
          enableSlices="x"
          axisTop={null}
          axisRight={null}
          axisBottom={{ tickSize: 0, tickPadding: 8, format: bottomTickFormatter }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 16,
            tickValues: 4,
            format: (val) => (val === 0 ? 0 : formatMethods[valueType](val)),
          }}
          enableGridX={false}
          gridYValues={4}
          onMouseEnter={(_, event) => (event.target.style.cursor = "pointer")}
          onMouseLeave={(_, event) => (event.target.style.cursor = "default")}
          sliceTooltip={renderTooltip}
          theme={chartTheme}
          animate={false}
        />
      </div>
    </>
  );
};

Line.propTypes = {
  data: PropTypes.array,
  bottomTickFormatter: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valueType: PropTypes.oneOf(["percent", "currency", "unformatted"]),
  renderTooltip: PropTypes.func,
  noDataMessage: PropTypes.string,
  height: PropTypes.number,
};

export default Line;
