import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlanPerformance from "./PlanPerformance";
import ScrubbedAmountsOfHighestVolumePlans from "./ScrubbedAmountsOfHighestVolumePlans";
import useFetch from "../../hooks/useFetch";
import Notice from "../../common/Notice";

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const PlanCards = ({ customerId }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch(`/api/customer_report/${customerId}/plans`);

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving plan data",
      });
    }
  }, [error]);

  return (
    <>
      <div className="cards-container cards-container--row">
        <ScrubbedAmountsOfHighestVolumePlans data={data?.chart} isLoading={isLoading} />
        <PlanPerformance data={data?.table} isLoading={isLoading} />
      </div>
      <Notice details={notice} />
    </>
  );
};

PlanCards.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default PlanCards;
