import React, { useEffect, useState } from "react";
import Line from "../common/charts/Line";
import Notice from "../common/Notice";
import useFetch from "../hooks/useFetch";
import { formatToPercent } from "../utils/numberUtils";

const ScrubRatesOfHighestVolumeCustomers = () => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving scrub rates of highest volume customers data",
  });

  const { data, isLoading, error } = useFetch("/api/dashboard/scrub_rates_by_customer");

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  function renderTooltip({ slice: { points } }) {
    const quarter = points[0].data.x;

    return (
      <div className="tooltip__content">
        <div className="tooltip__content__title">{quarter}</div>
        <div className="tooltip__content__details">
          {[...points].reverse().map((point) => {
            const scrubRate = formatToPercent(point.data.y, 1);
            const customerName = point.serieId;

            return (
              <div key={point.id} className="tooltip__content__details__row">
                <span
                  className="tooltip__content__details__symbol"
                  style={{ backgroundColor: point.serieColor }}
                />
                <span>
                  {scrubRate} {customerName}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card" data-testid="scrub-rates-of-highest-volume-customers">
        <div className="card__title">Scrub Rates of Highest Volume Customers</div>
        <Line
          data={data || []}
          valueType="percent"
          height={160}
          isLoading={isLoading}
          renderTooltip={renderTooltip}
          noDataMessage="No data was retrieved."
        />
      </div>
      <Notice details={notice} />
    </>
  );
};

export default ScrubRatesOfHighestVolumeCustomers;
