import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { primaryText } = colors;

const fontFamily = "Roboto, sans-serif";

const chartTextStyle = {
  fill: primaryText,
  fontSize: 12,
  fontFamily,
};

const chartTheme = {
  axis: {
    ticks: {
      text: chartTextStyle,
    },
  },
};

const noDataChartTheme = {
  axis: {
    ticks: {
      text: { ...chartTextStyle, opacity: 0.5 },
    },
  },
};

export { chartTextStyle, chartTheme, noDataChartTheme };
