import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import Donut from "../common/charts/Donut";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";
import LoadingSkeletonWrapper from "../common/LoadingSkeletonWrapper";

const scrubColors = [
  colors.darkTeal,
  colors.peachOrange,
  colors.purpleBlue,
  colors.goldenYellow,
  colors.lightPurple,
  colors.lightBlue,
];

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const ScrubTypeBreakdown = ({ currentQuarter }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const [activeItem, setActiveItem] = useState(null);
  const { data, isLoading, error } = useFetch("/api/dashboard/scrubbed_amounts_by_type");

  const donutColors = scrubColors.slice(-data?.length);

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving metrics data",
      });
    }
  }, [error]);

  const hasData = data?.length > 0;

  const donutChartData = useMemo(
    () => data?.map((datum, index) => ({ ...datum, color: donutColors[index] })),
    [data],
  );

  const totalValue = useMemo(() => data?.reduce((acc, { value }) => acc + value, 0), [data]);

  const renderCenterText = ({ centerX, centerY }) => {
    const currHoveredObj = donutChartData?.find(({ id }) => id === activeItem);
    const centerText = formatToPercent(currHoveredObj?.percentOfTotal);

    return (
      <text className="donut__center-text" x={centerX} y={centerY} dominantBaseline="central">
        {centerText}
      </text>
    );
  };

  return (
    <div className="card">
      <div className="card__title">Scrub Type Breakdown ({currentQuarter})</div>
      <div className="donut__container">
        <Donut
          data={hasData ? donutChartData : []}
          isLoading={isLoading}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          renderCenterText={renderCenterText}
        />
        {(isLoading || hasData) && (
          <div className="donut__table">
            {donutChartData?.map(({ id: scrubType, value, color }) => {
              return (
                <div
                  key={scrubType}
                  className={`donut__table__row donut__table__row--body ${scrubType === activeItem ? "donut__table__row--active" : ""}`}
                  onMouseEnter={() => setActiveItem(scrubType)}
                  onMouseLeave={() => setActiveItem(null)}
                >
                  <div className="donut__table__body__item--id">
                    <LoadingSkeletonWrapper isLoading={isLoading}>
                      <div style={{ backgroundColor: color }} />
                      <span>{scrubType}</span>
                    </LoadingSkeletonWrapper>
                  </div>
                  <div
                    className="donut__table__row__item--align-right"
                    style={{ flex: isLoading ? 1 : 0, paddingLeft: 16 }}
                  >
                    <LoadingSkeletonWrapper isLoading={isLoading}>
                      {formatCurrency(value, true)}
                    </LoadingSkeletonWrapper>
                  </div>
                </div>
              );
            })}
            <div className="donut__table__row donut__table__row--footer">
              <div>Total</div>
              <div className="donut__table__row__item--align-right">
                <LoadingSkeletonWrapper isLoading={isLoading}>
                  {formatCurrency(totalValue, true)}
                </LoadingSkeletonWrapper>
              </div>
            </div>
          </div>
        )}
      </div>
      <Notice details={notice} />
    </div>
  );
};

ScrubTypeBreakdown.propTypes = {
  currentQuarter: PropTypes.string.isRequired,
};

export default ScrubTypeBreakdown;
