export const formatCurrency = (value, roundToDollar = false) => {
  if (isNaN(value)) return null;

  const props = {
    style: "currency",
    currency: "USD",
  };

  if (roundToDollar) {
    props.maximumFractionDigits = 0;
    props.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat("en-US", props).format(value);
};

export const addCommasToNumber = (number) => {
  if (number === null || number === undefined || isNaN(number)) return number;
  const num = Number(number);

  return num.toLocaleString();
};

export const formatToPercent = (input, decimalPlaces = 0) => {
  if (input === null || input === undefined || isNaN(input)) return input;
  const num = Number(input);

  return num.toLocaleString("en-US", {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const formatToCompactNumber = (number) => {
  if (number === null || number === undefined || isNaN(number)) return number;

  return number.toLocaleString("en-US", {
    notation: "compact",
    compactDisplay: "short",
  });
};
