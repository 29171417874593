import React from "react";
import PropTypes from "prop-types";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";

const CalculationTable = ({ headerLabel, headerValue = 0, items }) => (
  <div className="calculation-table-container">
    <table className="calculation-table">
      <thead>
        <tr>
          <th>{headerLabel}</th>
          <th>{formatCurrency(headerValue)}</th>
          <th>100%</th>
        </tr>
      </thead>
      <tbody>
        {items.map(({ label, amount }, index) => (
          <tr key={index}>
            <td>{label}</td>
            <td>{formatCurrency(amount || 0)}</td>
            <td>{formatToPercent(headerValue === 0 ? 0 : (amount || 0) / headerValue)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

CalculationTable.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  headerValue: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default CalculationTable;
