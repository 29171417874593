import React from "react";
import PropTypes from "prop-types";
import { Add, ArrowRight } from "../common/icons";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const RecentInvoices = ({ recentInvoices = [] }) => {
  return (
    <div className="card" style={{ height: 0, minHeight: "100%" }} data-testid="recent-invoices">
      <div className="card__title">Recent Invoices</div>
      <a className="btn" href="/invoices/new" style={{ marginBottom: 24 }}>
        <Add color={colors.white} size={20} />
        <span>Add New Invoice</span>
      </a>
      <div className="info-card__container">
        {recentInvoices?.map((invoice) => (
          <div key={invoice.id} className="info-card">
            <div className="info-card__details">
              <div className="info-card__details__text">
                {invoice.customer.name} {invoice.number}
              </div>
              <div className="info-card__details__subtext">
                <span>{formatCurrency(invoice.totalAmount)}</span>
                <span>{formatToPercent(invoice.scrubRate)} Scrubbed</span>
              </div>
            </div>
            <a
              className="icon-btn icon-btn--secondary"
              href={`/invoices/${invoice.id}`}
              style={{ flexShrink: 0 }}
            >
              <ArrowRight size={20} />
            </a>
          </div>
        ))}
        <a className="btn btn--tertiary" href="/invoices">
          View All Invoices
        </a>
      </div>
    </div>
  );
};

RecentInvoices.propTypes = {
  recentInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string,
      totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      scrubRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      customer: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default RecentInvoices;
