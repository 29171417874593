import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { Skeleton } from "@mui/material";
import CustomLegend from "./CustomLegend";
import NoResults from "../../common/NoResults";
import { chartTheme, chartTextStyle } from "./themes";
import textWidth from "../../utils/textWidth";
import { formatToCompactNumber, formatToPercent } from "../../utils/numberUtils";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { primaryBackground } = colors;

const { fontSize, fontFamily } = chartTextStyle;

const PADDING = 16;

const valueTypes = {
  PERCENT: "percent",
  CURRENCY: "currency",
  UNFORMATTED: "unformatted",
};

const formatMethods = {
  [valueTypes.PERCENT]: formatToPercent,
  [valueTypes.CURRENCY]: formatToCompactNumber,
  [valueTypes.UNFORMATTED]: formatToCompactNumber,
};

const getDataTypeConfig = (valueType, formatMethod) => {
  switch (valueType) {
    case "percent":
      return {
        valueScale: { type: "linear", min: 0, max: 100 },
        indexScale: { type: "band", round: true },
        axisLeft: {
          tickValues: [0, 25, 50, 75, 100],
          tickSize: 0,
          tickPadding: PADDING,
          format: (val) => `${val}%`,
        },
        gridYValues: [0, 25, 50, 75, 100],
      };
    case "currency":
      return {
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        axisLeft: {
          tickSize: 0,
          tickPadding: PADDING,
          tickValues: 5,
          format: (val) => (val === 0 ? val : `$${formatMethod(val)}`),
        },
        gridYValues: 5,
      };
    case "unformatted":
      return {
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        axisLeft: {
          tickSize: 0,
          tickPadding: PADDING,
          format: (val) => (val === 0 ? val : formatMethod(val)),
        },
      };
  }
};

const Bar = ({
  data = [],
  groupMode = "stacked",
  indexBy,
  keys,
  height = 400,
  colors,
  showLegend = false,
  valueType = "unformatted",
  renderTooltip = () => {},
  isLoading,
  noDataMessage = "No data available at this time.",
}) => {
  const legendProps = keys.map((key, i) => ({ label: key, color: colors[i] }));

  const formatMethod = formatMethods[valueType];

  const getLeftMargin = () => {
    if (valueType === valueTypes.PERCENT) return textWidth("100%", fontSize, fontFamily);

    return Math.max(
      ...data.map((dataPoint) => {
        let text = formatMethod(dataPoint[keys]);

        if (valueType === valueTypes.CURRENCY) {
          text = `$${text}`;
        }

        return textWidth(text, fontSize, fontFamily);
      }),
    );
  };

  const leftMargin = getLeftMargin();

  if (!isLoading && !data.length)
    return (
      <NoResults
        description={noDataMessage}
        height={height}
        imgWidth={80}
        backgroundColor={primaryBackground}
      />
    );

  if (isLoading) {
    return <Skeleton variant="rounded" height={height} data-testid="bar-loading-skeleton" />;
  }

  return (
    <>
      {showLegend && <CustomLegend legendProps={legendProps} />}
      <div className="bar" style={{ height: `${height}px` }}>
        <ResponsiveBar
          data={data}
          isLoading={isLoading}
          indexBy={indexBy}
          keys={keys}
          margin={{
            top: 10,
            right: 0,
            bottom: 25,
            left: leftMargin + PADDING,
          }}
          padding={0.6}
          {...(groupMode === "grouped" ? { innerPadding: 4 } : {})}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisBottom={{ tickSize: 0, tickPadding: 8 }}
          groupMode={groupMode}
          enableLabel={false}
          onMouseEnter={(_, event) => (event.target.style.cursor = "pointer")}
          onMouseLeave={(_, event) => (event.target.style.cursor = "default")}
          tooltip={renderTooltip}
          theme={chartTheme}
          {...getDataTypeConfig(valueType, formatMethod)}
        />
      </div>
    </>
  );
};

Bar.propTypes = {
  data: PropTypes.array,
  valueType: PropTypes.oneOf(["percent", "currency", "unformatted"]),
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupMode: PropTypes.oneOf(["stacked", "grouped"]),
  renderTooltip: PropTypes.func,
  height: PropTypes.number,
  showLegend: PropTypes.bool,
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  noDataMessage: PropTypes.string,
};

export default Bar;
