import React from "react";
import PropTypes from "prop-types";
import NameValuePair from "../../common/NameValuePair";
import { Download } from "../../common/icons";
import { formatCurrency, formatToPercent } from "../../utils/numberUtils";
import { formatDateLongWithFullMonth } from "../../utils/dateTimeUtils";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const Metadata = ({ invoice }) => {
  const { id, scrubbedAmount, totalAmount, scrubRate, validatedRebateAmount, date, dueDate } =
    invoice || {};

  return (
    <div className="card metadata">
      <div className="metadata__main">
        <NameValuePair name="Scrubbed Amount" value={formatCurrency(scrubbedAmount)} isLargeValue />
        <div className="metadata__group">
          <NameValuePair name="Total Invoice Amount" value={formatCurrency(totalAmount)} />
          <NameValuePair name="Scrub Rate" value={formatToPercent(scrubRate, 1)} />
          <NameValuePair name="Validated Amount" value={formatCurrency(validatedRebateAmount)} />
        </div>
      </div>
      <div className="metadata__group">
        <NameValuePair name="Invoice Date" value={formatDateLongWithFullMonth(date)} />
        <NameValuePair name="Due Date" value={formatDateLongWithFullMonth(dueDate)} />
      </div>
      <a className="btn" href={`/invoices/${id}/export`} data-turbo="false">
        <Download color={colors.white} size={20} />
        <span>Download</span>
      </a>
    </div>
  );
};

Metadata.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string,
    date: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    totalAmount: PropTypes.string.isRequired,
    validatedRebateAmount: PropTypes.string.isRequired,
    scrubbedAmount: PropTypes.string.isRequired,
    scrubRate: PropTypes.string.isRequired,
    customerName: PropTypes.string,
  }).isRequired,
};

export default Metadata;
