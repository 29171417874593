import React from "react";
import PropTypes from "prop-types";

const NameValuePair = ({ name, value, isLargeValue = false }) => (
  <div>
    <div className="name-value-pair__name">{name}</div>
    <div
      className={`name-value-pair__value ${isLargeValue ? "name-value-pair__value--large" : ""}`}
    >
      {value}
    </div>
  </div>
);

NameValuePair.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isLargeValue: PropTypes.bool,
};

export default NameValuePair;
