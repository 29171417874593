import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const LoadingSkeletonWrapper = ({
  isLoading = false,
  children = <></>,
  width = "80%",
  dataTestId = "",
}) => {
  if (isLoading) return <Skeleton animation="wave" width={width} data-testid={dataTestId} />;

  return children;
};

LoadingSkeletonWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.string,
};

export default LoadingSkeletonWrapper;
