import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "../../hooks/useFetch";
import HorizontalBar from "../../common/charts/HorizontalBar";
import ToggleSwitch from "../../common/ToggleSwitch";
import Notice from "../../common/Notice";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";
import { addCommasToNumber, formatCurrency } from "../../utils/numberUtils";

const { secondary, highlightBlue } = colors;
const TOGGLE_SWITCH_OPTIONS = { amount: "amount", lines: "lines" };

const DistributionOfPlans = ({ invoiceId }) => {
  const [selectedToggleOption, setSelectedToggleOption] = useState(TOGGLE_SWITCH_OPTIONS.amount);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving Distribution of Plans data",
  });

  const { data, isLoading, error } = useFetch(`/api/invoices/${invoiceId}/scrub_details_by_plan`);

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  const isAmountToggleSelected = selectedToggleOption === TOGGLE_SWITCH_OPTIONS.amount;
  const chartData =
    data
      ?.map((obj) => ({
        plan: obj.plan,
        Scrubbed: obj[selectedToggleOption].scrubbed,
        Validated: obj[selectedToggleOption].notScrubbed,
      }))
      .reverse() || [];

  function renderTooltip({ data: { plan, Scrubbed = 0, Validated = 0 } }) {
    const formatValue = (value) => {
      return isAmountToggleSelected
        ? formatCurrency(value)
        : `${addCommasToNumber(value)} ${value === 1 ? "Line" : "Lines"}`;
    };

    return (
      <div className="tooltip__content">
        <div className="tooltip__content__title" style={{ marginBottom: 16 }}>
          {plan}
        </div>
        <div className="tooltip__content__details">
          <div className="tooltip__content__details__row">
            <div
              className="tooltip__content__details__symbol"
              style={{ backgroundColor: secondary }}
            />
            <span>{formatValue(Scrubbed)} Scrubbed</span>
          </div>
          <div className="tooltip__content__details__row">
            <div
              className="tooltip__content__details__symbol"
              style={{ backgroundColor: highlightBlue }}
            />
            <span>{formatValue(Validated)} Validated</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card" data-testid="distribution-of-plans">
        <div className="card__actions">
          <div className="card__title">Distribution of Plans</div>
          <ToggleSwitch
            options={["$ Amount", "Lines"]}
            onSelection={() => {
              setSelectedToggleOption((prev) =>
                prev === TOGGLE_SWITCH_OPTIONS.amount
                  ? TOGGLE_SWITCH_OPTIONS.lines
                  : TOGGLE_SWITCH_OPTIONS.amount,
              );
            }}
          />
        </div>
        <HorizontalBar
          data={chartData}
          showLegend={true}
          isLoading={isLoading}
          keys={["Scrubbed", "Validated"]}
          indexBy="plan"
          colors={[secondary, highlightBlue]}
          height={265}
          renderTooltip={renderTooltip}
          noDataMessage="There is no data that matches your filter selection"
          valueType={isAmountToggleSelected ? "currency" : "unformatted"}
          bottomTickValues={3}
        />
      </div>
      <Notice details={notice} />
    </>
  );
};

DistributionOfPlans.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default DistributionOfPlans;
